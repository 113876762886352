import React from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG } from '../../../_metronic/helpers'
import { useLayout } from '../../../_metronic/layout/core'
import { andRoles, Role, useUserInfo } from '../../../utils/keycloak'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  requiredRole?: Role
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  requiredRole = andRoles(),
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config
  const userInfo = useUserInfo()
  if (!requiredRole.hasRole(userInfo)) {
    return <></>
  }

  return (
    <div className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')} data-kt-menu-trigger="click">
      <span className={clsx('menu-link', { active: isActive })}>
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-2" />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && (
          <span className="menu-icon">
            <i className={clsx('bi fs-5 fa-fw me-3', fontIcon)}></i>
          </span>
        )}
        <span className="menu-title">{title}</span>
        <span className="menu-arrow"></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>{children}</div>
    </div>
  )
}

export { AsideMenuItemWithSub }

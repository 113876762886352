import { FC, createContext, useCallback, useReducer, useEffect, useContext } from 'react'
import type { Reducer } from 'react'
import _ from 'lodash'
import { KeycloakManager, getKeycloakContext, KEYCLOAK_CONTEXT_CHANGE_EVENT } from './KeyCloakManager'

/**
 * 현재 로그인한 유저 정보.
 */
export interface UserInfo {
  /**
   * 유저 인증 여부.
   *
   * @default false
   */
  isAuthenticated: boolean

  /**
   * 유저명.
   *
   * @default ""
   */
  name: string

  /**
   * 유저 이메일 주소.
   *
   * @default ""
   */
  email: string

  /**
   * 유저 전화번호.
   */
  phoneNumber?: string

  /**
   * 유저의 Realm 역할 목록.
   *
   * @default []
   */
  realmRoles: Array<string>

  /**
   * 유저의 현 Keycloak 클라이언트 역할 목록.
   *
   * @default []
   */
  clientRoles: Array<string>
}

const DEFAULT_USER_INFO: UserInfo = {
  isAuthenticated: false,
  name: '',
  email: '',
  realmRoles: [],
  clientRoles: [],
}

const UserInfoContext = createContext<UserInfo>(DEFAULT_USER_INFO)

function getUserInfo(): UserInfo {
  const context = getKeycloakContext()
  return {
    isAuthenticated: context.isAuthenticated,
    name: context.identity.name,
    email: context.identity.email,
    phoneNumber: context.identity.phoneNumber,
    realmRoles: context.realmRoles,
    clientRoles: context.clientRoles,
  }
}

// React Hook Reducer
function updateUserInfo(prev: UserInfo): UserInfo {
  const current = getUserInfo()
  // 유저 정보에 변동이 생긴 경우에만 업데이트 수행
  return !_.isEqual(prev, current) ? current : prev
}

const KeycloakManagerContext = createContext<KeycloakManager>({
  async logout() {},
  stopRefresh() {},
})

/**
 * 인증 정보를 제공하는 프로바이더.
 *
 * @param keycloakManager 현재 인증 절차를 수행하는 Keycloak 매니저 인스턴스
 * @param children
 * @constructor
 *
 * @see useUserInfo()
 * @see useKeycloakManager()
 */
export const AuthenticationProvider: FC<{ keycloakManager: KeycloakManager }> = ({ keycloakManager, children }) => {
  const [userInfo, dispatch] = useReducer<Reducer<UserInfo, any>>(updateUserInfo, getUserInfo())
  const updateIfChanged = useCallback(dispatch, [dispatch])

  useEffect(() => {
    // 스토리지에 변동이 생긴 경우 필요 시 업데이트
    window.addEventListener('storage', updateIfChanged)
    // 스토리지 변동은 현재 브라우저 탭에서는 인식이 안 되는 경우가 많아 커스텀 이벤트 리스너 생성
    window.addEventListener(KEYCLOAK_CONTEXT_CHANGE_EVENT, updateIfChanged)
    return () => {
      window.removeEventListener('storage', updateIfChanged)
      window.removeEventListener(KEYCLOAK_CONTEXT_CHANGE_EVENT, updateIfChanged)
    }
  }, [updateIfChanged])

  return (
    <KeycloakManagerContext.Provider value={keycloakManager}>
      <UserInfoContext.Provider value={userInfo}>{children}</UserInfoContext.Provider>
    </KeycloakManagerContext.Provider>
  )
}

/**
 * 현재 유저 정보를 반환한다.
 *
 * @requires AuthenticationProvider
 */
export function useUserInfo() {
  return useContext(UserInfoContext)
}

/**
 * 현재 Keycloak 매니저를 반환한다.
 *
 * @requires AuthenticationProvider
 */
export function useKeycloakManager() {
  return useContext(KeycloakManagerContext)
}

import React from 'react'
import { PageTitle } from '../layouts'
import { WarningPage } from '../components'

const PermissionDenied: React.FC = () => {
  return (
    <>
      <PageTitle description="Permission Denied">접근 권한이 없습니다</PageTitle>
      <WarningPage title="접근 권한이 없습니다!" />
    </>
  )
}

export default PermissionDenied

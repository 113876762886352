import React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import { toAbsoluteUrl } from '../_metronic/helpers'
import styled from 'styled-components'

const FullHeightContainer = styled(Container)`
  height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
`

const Logo = styled(Image)`
  max-width: 100px;
`

const Title = styled.h1``

const Unauthenticated: React.FC = () => {
  return (
    <FullHeightContainer className="text-center d-flex justify-content-center align-items-center">
      <Row>
        <Col>
          <Logo src={toAbsoluteUrl('/media/logos/logo-circular.png')} />
          <Title className="mt-6">로그인이 필요합니다.</Title>
          <Button variant="outline-primary" href="/">
            메인 페이지로
          </Button>
        </Col>
      </Row>
    </FullHeightContainer>
  )
}

export default Unauthenticated

import React from 'react'
import { ListGroupItem, Row, Badge } from 'react-bootstrap'
import { Station } from '../../../types'
import { toVerbose } from '../../../utils/models'
import styled from 'styled-components'

const Col = styled.div`
  flex: 0 0 content;
`

type Props = {
  station: Station
  key: string
  onClick: () => void
}

export const StationItem: React.FC<Props> = ({ station, onClick }) => {
  const level = toVerbose(station.level)
  return (
    <ListGroupItem onClick={onClick} className="cursor-pointer">
      <Row>
        <Col>
          <Badge bg={level === '광역' ? 'danger' : level === '지역' ? 'primary' : 'light'} text="white">
            {level} 정류소
          </Badge>
        </Col>
        <Col>{station.name}</Col>
      </Row>
    </ListGroupItem>
  )
}

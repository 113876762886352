import React from 'react'
import styled from 'styled-components'

export const KeyValueContainer: React.FC = ({ children }) => (
  <table className="table mb-0">
    <tbody>{children}</tbody>
  </table>
)

export const KeyValue = styled.tr``

export const Key = styled.td`
  font-weight: 900 !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
`
export const Value = styled.td`
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
`

import React, { useCallback, useMemo } from 'react'
import { History } from 'history'
import { PageTitle } from '../../layouts'
import moment, { Moment } from 'moment'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Card, Row, Col } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffectOnce } from '../../utils/hooks'
import QueryString from 'qs'
import { useDate } from './DateProvider'

export enum Recurrence {
  DAILY,
  WEEKLY,
  MONTHLY,
  ANNUAL,
}

type Prop = {
  viewId: string
  title: string
  recurrence?: Recurrence
}

function toStartDateTime(m: Moment, unitTime: moment.unitOfTime.DurationConstructor = 'day') {
  return m.clone().startOf(unitTime).add(8, 'hours')
}

function updateHistory(history: History, date: Moment, update: boolean = true) {
  const queryParam = QueryString.parse(history.location.search, { ignoreQueryPrefix: true })
  if (update) {
    queryParam.date = `${date.format('YYYY-MM-DD')}`
  } else if (queryParam.date) {
    delete queryParam.date
  } else {
    return
  }
  history.push({ ...history.location, search: QueryString.stringify(queryParam) })
}

const Analytics: React.FC<Prop> = ({ viewId, title, recurrence = Recurrence.DAILY }) => {
  const history = useHistory()
  const location = useLocation()
  const todayMorning = useMemo(() => toStartDateTime(moment()), [])
  const [date, setDate] = useDate()

  const unitTime = {
    [Recurrence.DAILY]: 'day',
    [Recurrence.WEEKLY]: 'isoWeek',
    [Recurrence.MONTHLY]: 'month',
    [Recurrence.ANNUAL]: 'year',
  }[recurrence] as moment.unitOfTime.DurationConstructor

  useEffectOnce(() => {
    const { search: s } = location
    const { date: qDate } = QueryString.parse(s, { ignoreQueryPrefix: true })

    if (qDate) {
      const parsed = moment(qDate as string)
      if (!parsed.isValid() || todayMorning < parsed) {
        const startDateTime = toStartDateTime(todayMorning, unitTime)
        updateHistory(history, startDateTime)
        return
      }

      const startDateTime = toStartDateTime(parsed, unitTime)
      if (qDate !== startDateTime.format('YYYY-MM-DD')) {
        updateHistory(history, startDateTime)
        return
      }
      setDate(startDateTime)
      return
    }

    if (!date || todayMorning < date) {
      const startDateTime = toStartDateTime(todayMorning, unitTime)
      updateHistory(history, startDateTime)
      return
    }

    const startDateTime = toStartDateTime(date, unitTime)
    updateHistory(history, startDateTime)
  })

  const moveDateByUnit = useCallback(
    (amount) => {
      const startDateTime = toStartDateTime(date.clone().add(amount, unitTime), unitTime)
      updateHistory(history, startDateTime)
    },
    [date, unitTime, history],
  )

  const moveLatestDate = useCallback(() => {
    const startDateTime = toStartDateTime(todayMorning, unitTime)
    updateHistory(history, startDateTime)
  }, [todayMorning, unitTime, history])

  const startDateTime = date
  const endDateTime = startDateTime.clone().add(1, unitTime)

  let displayDate = ''
  let displayPrev = ''
  let displayNext = ''
  let displayLatestDate = ''
  switch (recurrence) {
    case Recurrence.DAILY:
      const weekday = '월화수목금토일'[(startDateTime.weekday() + 6) % 7]
      displayDate = `${startDateTime.format('YYYY년 M월 D일')} (${weekday}요일)`
      displayPrev = '전날'
      displayNext = '다음날'
      displayLatestDate = '오늘'
      break
    case Recurrence.WEEKLY:
      const fr = startDateTime.format('YYYY년 M월 D일')
      const dateBeforeEnd = endDateTime.clone().add(-1, 'day')
      let to = ''
      switch (true) {
        case startDateTime.year() !== dateBeforeEnd.year():
          to = dateBeforeEnd.format('YYYY년 M월 D일')
          break
        case startDateTime.month() !== dateBeforeEnd.month():
          to = dateBeforeEnd.format('M월 D일')
          break
        default:
          to = dateBeforeEnd.format('D일')
      }
      displayDate = `${fr}부터 ${to}`
      displayPrev = '전주'
      displayNext = '다음주'
      displayLatestDate = '이번주'
      break
    case Recurrence.MONTHLY:
      displayDate = `${startDateTime.format('YYYY년 M월')}`
      displayPrev = '전달'
      displayNext = '다음달'
      displayLatestDate = '이번달'
      break
    case Recurrence.ANNUAL:
      displayDate = `${startDateTime.format('YYYY년')}`
      displayPrev = '전년'
      displayNext = '다음년'
      displayLatestDate = '올해'
      break
  }
  return (
    <>
      <PageTitle>Today 분석</PageTitle>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <h1 className="mb-8 d-flex flex-row align-items-center fs-2x">
                <div>
                  {displayDate} {title}
                </div>
                <ButtonGroup className="ms-8">
                  <Button size="lg" variant="outline-primary" onClick={() => moveDateByUnit(-1)}>
                    {displayPrev}
                  </Button>
                  <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={() => moveDateByUnit(1)}
                    disabled={startDateTime.clone().add(1, unitTime).isAfter(todayMorning)}
                  >
                    {displayNext}
                  </Button>
                  <Button size="lg" variant="outline-primary" onClick={moveLatestDate}>
                    {displayLatestDate}
                  </Button>
                </ButtonGroup>
              </h1>
              {todayMorning < endDateTime ? '작성중인 통계입니다. 주의해 주세요.' : ''}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <iframe
        key={startDateTime.toISOString()}
        title={title}
        className="full-height"
        src={`https://kibana-proxy.vtov.studio/app/dashboards#/view/${viewId}?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'${startDateTime.toISOString()}'%2Cto%3A'${endDateTime.toISOString()}'))&hide-filter-bar=true`}
      />
    </>
  )
}

export default Analytics

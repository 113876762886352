/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import styled from 'styled-components'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { andRoles, Role, roles, useUserInfo } from '../../../utils/keycloak'

const Seperator = styled.hr`
  width: 100%;
  color: white;
`

const adminRequired = roles('admin')
const noRolesRequired = andRoles()

const AsideSection: React.FC<{ title?: String; requiredRole?: Role }> = ({
  title,
  requiredRole = noRolesRequired,
  children,
}) => {
  const userInfo = useUserInfo()
  if (!requiredRole.hasRole(userInfo)) {
    return <></>
  }

  return (
    <>
      {title && (
        <div className="menu-item">
          <div className="menu-content mt-4 pb-2">
            <Seperator className="m-0 mb-6" />
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">{title}</span>
          </div>
        </div>
      )}
      {children}
    </>
  )
}

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem to="/" title="VTOV 포탈 홈" fontIcon="fas fa-home" exact />
      <AsideMenuItem to="/profile" title="내 프로필" fontIcon="fas fa-user" />

      <AsideSection title={'Dashboard'}>
        <AsideMenuItem to="/dashboard" exact title="Today 대시보드" fontIcon="fas fa-map" />
        <AsideMenuItem to="/dashboard/stats" title="Today 실시간 통계" fontIcon="fas fa-chart-area" />
        <AsideMenuItemWithSub
          to="/dashboard/analytics"
          title="Today 분석"
          fontIcon="fas fa-chart-area"
          requiredRole={adminRequired}
        >
          <AsideMenuItemWithSub to="/dashboard/analytics/summary/daily" title="요약">
            <AsideMenuItem to="/dashboard/analytics/summary/daily" exact title="데일리" hasBullet />
            <AsideMenuItem to="/dashboard/analytics/summary/weekly" exact title="위클리" hasBullet />
            <AsideMenuItem to="/dashboard/analytics/summary/monthly" exact title="먼슬리" hasBullet />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to="/dashboard/analytics/operation/daily" title="운영">
            <AsideMenuItem to="/dashboard/analytics/operation/daily" exact title="데일리" hasBullet />
            <AsideMenuItem to="/dashboard/analytics/operation/weekly" exact title="위클리" hasBullet />
            <AsideMenuItem to="/dashboard/analytics/operation/monthly" exact title="먼슬리" hasBullet />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to="/dashboard/analytics/infra/daily" title="인프라">
            <AsideMenuItem to="/dashboard/analytics/infra/daily" exact title="데일리" hasBullet />
            <AsideMenuItem to="/dashboard/analytics/infra/weekly" exact title="위클리" hasBullet />
            <AsideMenuItem to="/dashboard/analytics/infra/monthly" exact title="먼슬리" hasBullet />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to="/dashboard/analytics/sales/monthly" title="세일즈">
            <AsideMenuItem to="/dashboard/analytics/sales/monthly" exact title="먼슬리" hasBullet />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to="/dashboard/analytics/fulfilment/monthly" title="풀필먼트별">
            <AsideMenuItem to="/dashboard/analytics/fulfilment/monthly" exact title="먼슬리" hasBullet />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to="/dashboard/analytics/fulfilment/monthly" title="파이낸스">
            <AsideMenuItem to="/dashboard/analytics/finance/annual" exact title="애뉴얼" hasBullet />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub>
        <AsideMenuItem to="/dashboard/loads" title="화물 조회" fontIcon="fas fa-box" />
        <AsideMenuItem to="/dashboard/trucks" title="트럭 조회" fontIcon="fas fa-truck" />
      </AsideSection>

      <AsideSection requiredRole={roles('clients:viewer')} title={'Client'}>
        <AsideMenuItem to="/clients" title="고객사 관리" fontIcon="fas fa-users" />
      </AsideSection>

      <AsideSection title={'Simulation'} requiredRole={adminRequired}>
        <AsideMenuItemWithSub to="/simulation/gangseo" title="강서지역" hasBullet>
          <AsideMenuItem to="/simulation/gangseo/map" exact title="지도" fontIcon="fas fa-map-marked-alt" />
          <AsideMenuItem to="/simulation/gangseo/rounds" title="운행 정보" fontIcon="fas fa-truck-moving" />
          <AsideMenuItem to="/simulation/gangseo/stats" exact title="통계 그래프" fontIcon="fas fa-chart-area" />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to="/simulation/gangnam" title="강서+강남3구" hasBullet>
          <AsideMenuItem to="/simulation/gangnam/map" exact title="지도" fontIcon="fas fa-map-marked-alt" />
          <AsideMenuItem to="/simulation/gangnam/rounds" title="운행 정보" fontIcon="fas fa-truck-moving" />
          <AsideMenuItem to="/simulation/gangnam/stats" exact title="통계 그래프" fontIcon="fas fa-chart-area" />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to="/simulation/seoul" title="서울전역" hasBullet>
          <AsideMenuItem to="/simulation/seoul/map" exact title="지도" fontIcon="fas fa-map-marked-alt" />
          <AsideMenuItem to="/simulation/seoul/rounds" title="운행 정보" fontIcon="fas fa-truck-moving" />
          <AsideMenuItem to="/simulation/seoul/stats" exact title="통계 그래프" fontIcon="fas fa-chart-area" />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to="/simulation/songdo" title="인천송도" hasBullet>
          <AsideMenuItem to="/simulation/songdo/map" exact title="지도" fontIcon="fas fa-map-marked-alt" />
          <AsideMenuItem to="/simulation/songdo/rounds" title="운행 정보" fontIcon="fas fa-truck-moving" />
          <AsideMenuItem to="/simulation/songdo/stats" exact title="통계 그래프" fontIcon="fas fa-chart-area" />
        </AsideMenuItemWithSub>
      </AsideSection>

      <AsideSection title={'Documents'} requiredRole={adminRequired}>
        <AsideMenuItemWithSub to="/api/docs" title="VTOV 내부 API" fontIcon="fas fa-laptop">
          <AsideMenuItem to="/api/docs" exact title="들어가기" fontIcon="fas fa-home" />
          <AsideMenuItem to="/api/docs/auth" exact title="인증 및 인가" fontIcon="fas fa-shield-alt" />
          <AsideMenuItem to="/api/docs/errors" exact title="에러 처리" fontIcon="fas fa-bug" />
          <AsideMenuItem to="/api/docs/releases" exact title="릴리즈 노트" fontIcon="fas fa-list-ul" />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to="/api/refs" title="제품별 내부 API 레퍼런스" fontIcon="fas fa-book">
          <AsideMenuItem to="/api/refs/client" exact title="Client API" fontIcon="fas fa-id-card-alt" />
          <AsideMenuItem to="/api/refs/order" exact title="Order API" fontIcon="fas fa-plus" />
          <AsideMenuItem to="/api/refs/region" exact title="Region API" fontIcon="fab fa-microsoft" />
          <AsideMenuItem to="/api/refs/driving" exact title="Driving API" fontIcon="fas fa-shipping-fast" />
          <AsideMenuItem to="/api/refs/shipping" exact title="Shipping API" fontIcon="fas fa-dolly" />
          <AsideMenuItem to="/api/refs/track" exact title="Track API" fontIcon="fas fa-project-diagram" />
          <AsideMenuItem to="/api/refs/trace" exact title="Trace API" fontIcon="fas fa-map" />
          <AsideMenuItem to="/api/refs/location" exact title="Location API" fontIcon="fas fa-landmark" />
          <AsideMenuItem to="/api/refs/util" exact title="Util API" fontIcon="fas fa-cogs" />
          <AsideMenuItem to="/api/refs/rescue" exact title="Rescue API" fontIcon="fas fa-fire-extinguisher" />
          <AsideMenuItem to="/api/refs/internal" exact title="Internal API" fontIcon="fas fa-home" />
        </AsideMenuItemWithSub>
      </AsideSection>

      <AsideSection requiredRole={adminRequired}>
        <div className="menu-item">
          <a target="_blank" className="menu-link" href="https://docs.vtov.kr">
            <i className="bi fs-5 fa-fw me-3 fas fa-file-invoice" />
            <span className="menu-title">
              TODAY 오픈API 레퍼런스 <i className="ms-1 fs-7 fa-fw fas fa-external-link-alt" />
            </span>
          </a>
        </div>
      </AsideSection>

      <AsideSection title={'External Links'} requiredRole={adminRequired}>
        <div className="menu-item">
          <a target="_blank" className="menu-link" href={`${process.env.REACT_APP_API_PORTAL_BASE_URL}/__admin__/`}>
            <i className="bi fs-5 fa-fw me-3 fas fa-user-lock" />
            <span className="menu-title">포탈 관리자 페이지</span>
          </a>
        </div>
        <div className="menu-item">
          <a target="_blank" className="menu-link" href="https://github.com/vtov-kr/portal-frontend">
            <i className="bi fs-5 fa-fw me-3 fab fa-github" />
            <span className="menu-title">GitHub 레포지토리</span>
          </a>
        </div>
      </AsideSection>
    </>
  )
}

import {
  CartState,
  CartType,
  DeliveryClass,
  Level,
  LoadState,
  OrderState,
  RoundState,
  RouteType,
  StationType,
  StatusEventType,
} from '../../types'

export function toVerbose(
  val:
    | Level
    | LoadState
    | CartState
    | CartType
    | DeliveryClass
    | RoundState
    | StatusEventType
    | OrderState
    | RouteType
    | StationType,
): string {
  switch (val) {
    case Level.Wide:
      return '광역'
    case Level.Local:
      return '지역'
    case Level.LastMile:
      return '라스트마일'
    case Level.Fulfillment:
      return '풀필먼트'
    case Level.PickUp:
      return '출고지'
    case Level.LMX:
      return '픽업'
    case LoadState.PendingCollect:
      return '집화대기'
    case LoadState.HoldingCollect:
      return '미인수'
    case LoadState.InCollect:
      return '집화중'
    case LoadState.InTransport:
      return '운송중'
    case LoadState.InDelivery:
      return '배송중'
    case LoadState.AtStation:
      return '정차중'
    case LoadState.HoldingDelivery:
      return '미배송'
    case LoadState.Quit:
      return '배송중단'
    case LoadState.Delivered:
      return '배송완료'
    case LoadState.OnHold:
      return '배송보류'
    case CartState.Empty:
      return '빈 카트'
    case CartState.BeforeUnloading:
      return '분류대기'
    case CartState.Unloading:
      return '화물 하차중'
    case CartState.Loading:
      return '화물 상차중'
    case CartState.StandBy:
      return '트럭 적재대기'
    case CartState.InShipping:
      return '운송중'
    case CartType.Idle:
      return '유휴카트'
    case CartType.Shipping:
      return '일반카트'
    case CartType.LocalDeliver:
      return '동네배송카트'
    case CartType.LocalCollect:
      return '동네집화카트'
    case CartType.LMReturn:
      return '반품집화카트'
    case DeliveryClass.Local:
      return '동네배송'
    case DeliveryClass.Evening:
      return '저녁배송'
    case DeliveryClass.Overnight:
      return '새벽배송'
    case DeliveryClass.Returning:
      return '반품배송'
    case RoundState.InShipping:
      return '운송중'
    case RoundState.OnWayBack:
      return '복귀중'
    case RoundState.AtStation:
      return '정차중'
    case RoundState.Finished:
      return '종료'
    case StatusEventType.PickingUpLoad:
      return '화물 인수'
    case StatusEventType.ArrivalAtStation:
      return '정류소 도착'
    case StatusEventType.DepartureFromStation:
      return '정류소 출발'
    case StatusEventType.ReturningToStation:
      return '정류소 복귀'
    case StatusEventType.DeliveringLoad:
      return '화물 배송'
    case OrderState.TakingOut:
      return '출고중'
    case OrderState.PendingCollect:
      return '집화대기'
    case OrderState.InShipping:
      return '운송중'
    case OrderState.Delivered:
      return '배송완료'
    case OrderState.Canceled:
      return '주문취소'
    case OrderState.Quit:
      return '배송중단'
    case RouteType.Wide:
      return '광역노선'
    case RouteType.Local:
      return '지역노선'
    case RouteType.LastMile:
      return 'LM노선'
    case RouteType.PickUp:
      return '픽업노선'
    case StationType.WideInDoor:
      return '광역'
    case StationType.WideOnTruck:
      return '광역 릴레이'
    case StationType.Local:
      return '지역'
    case StationType.PickUp:
      return '출고지'
    case StationType.RELAY:
      return '릴레이'
  }
}

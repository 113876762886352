import { useEffect, useRef, useState, EffectCallback, DependencyList } from 'react'

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useReady(): {
  isReady: boolean
  ready: () => void
} {
  const [isReady, setReady] = useState(false)
  return {
    isReady,
    ready: () => {
      setReady(true)
    },
  }
}

function useSingleRun(): {
  runSingle: (effect: EffectCallback) => void | (() => void)
  runSingleAsync: (effect: () => Promise<void>) => void
  cancel: () => void
} {
  const runnableRef = useRef(true)
  return {
    runSingle: (effect) => {
      if (runnableRef.current) {
        runnableRef.current = false
        return effect()
      }
    },
    runSingleAsync: (cb) => {
      if (runnableRef.current) {
        ;(async () => {
          runnableRef.current = false
          await cb()
        })()
      }
    },
    cancel: () => {
      runnableRef.current = true
    },
  }
}

export function useEffectOnce(effect: EffectCallback): boolean {
  const { isReady, ready } = useReady()
  const { runSingle } = useSingleRun()
  useEffect(() =>
    runSingle(() => {
      const destructor = effect()
      ready()
      return destructor
    }),
  )
  return isReady
}

export function useEffectWhen(ok: boolean, effect: EffectCallback, deps?: DependencyList) {
  useEffect(() => {
    if (!ok) {
      return
    }
    return effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ok, ...(deps ?? [])])
}

export function useEffectOnceWhen(ok: boolean, effect: EffectCallback): boolean {
  const { isReady, ready } = useReady()
  const { runSingle } = useSingleRun()
  useEffect(() => {
    if (!ok) {
      return
    }
    return runSingle(() => {
      const destructor = effect()
      ready()
      return destructor
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ok])
  return isReady
}

export type AsyncJobRunner = (fn: (isValid: () => boolean) => Promise<void>, shouldReset?: boolean) => void

export function useAsyncJob(): [AsyncJobRunner, boolean] {
  const ref = useRef(0)
  const doneRef = useRef(0)
  const [counter, setCounter] = useState(0)
  const [doneCounter, setDoneCounter] = useState(0)
  const [expiredCount, setExpiredCount] = useState(-1)
  return [
    (fn: (isValid: () => boolean) => Promise<void>, shouldReset?: boolean) => {
      if (shouldReset) {
        setExpiredCount(doneRef.current)
        return Promise.resolve()
      }
      const seq = ++ref.current
      setCounter(seq)
      ;(async () => {
        try {
          await fn(() => ref.current === seq)
        } finally {
          setDoneCounter(++doneRef.current)
        }
      })()
    },
    counter === doneCounter && expiredCount < counter,
  ]
}

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { runKeycloakManager } from './utils/keycloak'
;(async () => {
  const keycloakManager = await runKeycloakManager({
    url: process.env.REACT_APP_KEYCLOAK_URL || '',
    realm: process.env.REACT_APP_KEYCLOAK_REALM || '',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
  })

  ReactDOM.render(
    <React.StrictMode>
      <App keycloakManager={keycloakManager} />
    </React.StrictMode>,
    document.getElementById('root'),
  )
  reportWebVitals()
})()

import React, { FC, lazy } from 'react'
import styled from 'styled-components'
import { Redirect, Switch } from 'react-router-dom'
import { Route, ChildRoute } from './Route'

import NotFound from '../pages/NotFound'
import { TimeController } from '../pages/simulation/components'

const Container = styled.div`
  position: relative;
  width: 100%;
`

type Prop = {
  serviceKey: string
  title: string
  timeOption: {
    startTime: string
    count: number
    stepMinutes: 10
  }
}

const DashboardSet: FC<Prop> = ({ serviceKey, title, timeOption }) => {
  const Dashboard = lazy(() => import('../pages/simulation/Dashboard'))
  const Stats = lazy(() => import('../pages/simulation/Statistics'))
  const RoundList = lazy(() => import('../pages/simulation/RoundList'))
  const RoundDetail = lazy(() => import('../pages/simulation/RoundDetail'))

  return (
    <Switch>
      <Redirect path={`/simulation/${serviceKey}`} exact to={`/simulation/${serviceKey}/map`} />
      <Route
        path={`/simulation/${serviceKey}/map`}
        exact
        render={(props) => <Dashboard {...props} serviceKey={serviceKey} title={title} timeOption={timeOption} />}
        needFullScreen={true}
      />
      <Route
        path={`/simulation/${serviceKey}/stats`}
        exact
        render={(props) => <Stats {...props} serviceKey={serviceKey} title={title} timeOption={timeOption} />}
        needFullScreen={true}
      />
      <Route
        path={`/simulation/${serviceKey}/rounds`}
        exact
        render={(props) => <RoundList {...props} serviceKey={serviceKey} title={title} timeOption={timeOption} />}
        needFullScreen={true}
      />
      <Route
        path={`/simulation/${serviceKey}/rounds/:roundId`}
        exact
        render={(props) => <RoundDetail {...props} serviceKey={serviceKey} title={title} timeOption={timeOption} />}
        needFullScreen={true}
      />
    </Switch>
  )
}

const SimulationRoutes: FC = () => {
  return (
    <>
      <Switch>
        <ChildRoute
          path="/simulation/gangseo"
          render={(props) => (
            <DashboardSet
              {...props}
              serviceKey="gangseo"
              title="강서지역"
              timeOption={{
                startTime: '10:10',
                count: 101,
                stepMinutes: 10,
              }}
            />
          )}
        />
        <ChildRoute
          path="/simulation/gangnam"
          render={(props) => (
            <DashboardSet
              {...props}
              serviceKey="gangnam"
              title="강서+강남3구"
              timeOption={{
                startTime: '10:10',
                count: 139,
                stepMinutes: 10,
              }}
            />
          )}
        />
        <ChildRoute
          path="/simulation/seoul"
          render={(props) => (
            <DashboardSet
              {...props}
              serviceKey="seoul"
              title="서울전역"
              timeOption={{
                startTime: '10:10',
                count: 137,
                stepMinutes: 10,
              }}
            />
          )}
        />
        <ChildRoute
          path="/simulation/songdo"
          render={(props) => (
            <DashboardSet
              {...props}
              serviceKey="songdo"
              title="인천송도"
              timeOption={{
                startTime: '10:10',
                count: 137,
                stepMinutes: 10,
              }}
            />
          )}
        />
        <Route component={NotFound} />
      </Switch>
      <Switch>
        <ChildRoute path="/simulation/:serviceKey/rounds/:roundId" render={() => <Empty />} />
        <Container>
          <TimeController />
        </Container>
      </Switch>
    </>
  )
}

const Empty: FC = () => {
  return <></>
}

export default SimulationRoutes

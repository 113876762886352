import React from 'react'
import { useHistory } from 'react-router-dom'
import { FaExclamationTriangle } from 'react-icons/fa'
import { Button as BaseButton } from 'react-bootstrap'
import styled from 'styled-components'
import { Title } from './Title'

const Container = styled.div`
  text-align: center;
  padding-top: 10rem;
`
const Icon = styled(FaExclamationTriangle)`
  font-size: 8rem;
  color: ${(p) => p.theme.warning};
`

const Button = styled(BaseButton)`
  margin-top: ${(p) => p.theme.gutters[5]};
`

interface Props {
  title: string
  description?: string
}

const WarningPage: React.FC<Props> = ({ title, description }) => {
  const { goBack } = useHistory()
  return (
    <Container>
      <Icon />
      <Title>{title}</Title>
      <Button size="sm" variant="outline-secondary" onClick={goBack}>
        이전 페이지로
      </Button>
    </Container>
  )
}

export { WarningPage }

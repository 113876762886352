import React from 'react'
import { PageTitle } from '../layouts'
import { WarningPage } from '../components'

const NotFound: React.FC = () => {
  return (
    <>
      <PageTitle description="페이지가 존재하지 않습니다.">Not Found</PageTitle>
      <WarningPage title="존재하지 않는 페이지입니다!" />
    </>
  )
}

export default NotFound

import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { InfoItem } from './InfoItem'
import { Load, LoadState } from '../../../types'
import { Key, KeyValue, KeyValueContainer, Value } from './KeyValue'
import { lighten } from 'polished'

function formatTime(t?: string): string {
  return t ? moment(t).format('MM/DD HH:mm') : '-'
}

type Props = {
  load: null | Load
  redo: () => void
}

type StepperProp = {
  activeStep?: number
}

const StepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  min-width: 400px;
`

const StepperLine = styled.div`
  position: absolute;
  top: 9px;
  left: 30px;
  right: 30px;
  width: calc(100% - 60px);
  max-width: calc(100% - 60px);
  border: 1px dashed #aaa;
  z-index: 10;

  &.active {
    z-index: 20;
    border: 1px solid ${lighten(0.1, '#63adfe')};
  }
`

const Stepper: React.FC<StepperProp> = ({ activeStep, children }) => {
  const size = React.Children.toArray(children).length
  return (
    <StepperContainer>
      <StepperLine />
      <StepperLine
        className="active"
        style={{
          width: `calc(${((activeStep ?? 2) - 2) / (size - 1)} * (100% - 60px))`,
        }}
      />
      {children}
    </StepperContainer>
  )
}

type StepperItemProp = {
  active?: boolean
  current?: boolean
}

const StepperItemCircle = styled.div`
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #aaa;
  background-color: #eee;

  &.active {
    background-color: ${lighten(0.1, '#63adfe')};
    border: 1px solid #63adfe;
  }

  &.current {
    border: 1px solid #63adfe;
  }
`

const StepperItemWrapper = styled.div`
  text-align: center;
  width: 60px;
  position: relative;
  z-index: 30;
`

const StepperItem: React.FC<StepperItemProp> = ({ active, current, children }) => {
  return (
    <StepperItemWrapper>
      <StepperItemCircle className={`${active ? 'active' : ''} ${current ? 'current' : ''}`} />
      <div>{children}</div>
    </StepperItemWrapper>
  )
}

export const LoadInfo: React.FC<Props> = ({ load, redo }) => {
  if (!load) {
    return <></>
  }
  let step: number
  switch (load.state) {
    case LoadState.PendingCollect:
      step = 1
      break
    case LoadState.InCollect:
      step = 2
      break
    case LoadState.InTransport:
    case LoadState.AtStation:
      step = 3
      break
    case LoadState.InDelivery:
    case LoadState.OnHold:
      step = 4
      break
    default:
      step = 5
  }
  return (
    <>
      <InfoItem redo={redo} icon="box" title={`화물 ${load.id.substring(0, 5)} (${load.name})`}>
        <Stepper activeStep={step + 2}>
          <StepperItem active={step > 0} current={step >= 0}>
            화물등록
          </StepperItem>
          <StepperItem active={step > 1} current={step >= 1}>
            집화중
          </StepperItem>
          <StepperItem active={step > 2} current={step >= 2}>
            수송중
          </StepperItem>
          <StepperItem active={step > 3} current={step >= 3}>
            배송중
          </StepperItem>
          <StepperItem active={step > 4} current={step >= 4}>
            배송완료
          </StepperItem>
        </Stepper>
      </InfoItem>
      <InfoItem title="화물정보" subTitle={load.id}>
        <Row>
          <Col xs={6}>
            <KeyValueContainer>
              <KeyValue>
                <Key>배송유형</Key>
                <Value>{load.deliveryPreference}</Value>
              </KeyValue>
              <KeyValue>
                <Key>등록일시</Key>
                <Value>{formatTime(load.orderTime)}</Value>
              </KeyValue>
              <KeyValue>
                <Key>인수일시</Key>
                <Value>{formatTime(load.pickUpInfo?.pickUpTime)}</Value>
              </KeyValue>
              <KeyValue>
                <Key>배송일시</Key>
                <Value>{formatTime(load.deliveryInfo?.deliverTime)}</Value>
              </KeyValue>
            </KeyValueContainer>
          </Col>
          <Col xs={6}>
            <KeyValueContainer>
              <KeyValue>
                <Key>화물유형</Key>
                <Value>{load.category}</Value>
              </KeyValue>
              <KeyValue>
                <Key>화물크기</Key>
                <Value>
                  {load.dimension.width} X {load.dimension.depth} X {load.dimension.height} (cm)
                </Value>
              </KeyValue>
              <KeyValue>
                <Key>화물무게</Key>
                <Value>{load.weight}g</Value>
              </KeyValue>
              <KeyValue>
                <Key>깨짐주의</Key>
                <Value>{load.fragile ? 'Y' : 'N'}</Value>
              </KeyValue>
            </KeyValueContainer>
          </Col>
        </Row>
      </InfoItem>
      <InfoItem title="보낸이">
        <KeyValueContainer>
          <KeyValue>
            <Key>보낸이</Key>
            <Value>
              {load.sender.name} ({load.sender.phone})
            </Value>
          </KeyValue>
          <KeyValue>
            <Key>구역</Key>
            <Value>{load.sender.lastMileInfo.regionSetName}</Value>
          </KeyValue>
        </KeyValueContainer>
      </InfoItem>
      <InfoItem title="받는이">
        <KeyValueContainer>
          <KeyValue>
            <Key>받는이</Key>
            <Value>
              {load.receiver.name} ({load.receiver.phone})
            </Value>
          </KeyValue>
          <KeyValue>
            <Key>구역</Key>
            <Value>{load.receiver.lastMileInfo.regionSetName}</Value>
          </KeyValue>
        </KeyValueContainer>
      </InfoItem>
    </>
  )
}

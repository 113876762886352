export function arrayToMap<T extends Record<Key, string>, Key extends string = 'id'>(
  key: Key,
  arr: T[],
): Map<string, T> {
  return new Map(arr.map((e) => [e[key], e]))
}

export function verboseTime(sec: number): string {
  if (sec < 10) {
    const t = Math.floor(sec * 100) / 100
    return `${t}s`
  } else if (sec < 60) {
    const t = Math.floor(sec * 10) / 10
    return `${t}s`
  } else if (sec < 600) {
    const m = Math.floor(sec / 60)
    const s = Math.floor(sec - m * 60)
    if (s === 0) {
      return `${m}m`
    } else {
      return `${m}m${s}s`
    }
  } else if (sec < 3600) {
    const m = Math.floor(sec / 60)
    return `${m}m`
  } else {
    const h = Math.floor((10 * sec) / 3600) / 10
    return `${h}h`
  }
}

export function prettyNumber(n: number, unit?: string): string {
  if (!unit) {
    return Number(n).toLocaleString()
  }
  return `${Number(n).toLocaleString()}${unit}`
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useUserInfo, useKeycloakManager } from '../../../../utils/keycloak'
import { toAbsoluteUrl } from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const { logout } = useKeycloakManager()
  const userInfo = useUserInfo()
  const user = {
    pic: '',
    name: userInfo.name,
    email: userInfo.email,
    type: userInfo.email.endsWith('@vtov.kr') ? 'VTOV' : 'External',
  }
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={user.pic || toAbsoluteUrl('/media/logos/logo-circular.png')} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user.name}
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{user.type}</span>
            </div>
            <div className="fw-bold text-muted text-hover-primary fs-7">{user.email}</div>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to={'/profile'} className="menu-link px-5">
          내 프로필
        </Link>
      </div>

      <div className="menu-item px-5">
        <Link to="#" className="menu-link px-5" onClick={logout}>
          로그아웃
        </Link>
      </div>
    </div>
  )
}

export { HeaderUserMenu }

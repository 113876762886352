import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { InfoItem, StatContainer, StatItem } from './InfoItem'
import { Route, TruckWithActiveRound, Level, Cart, Load, LoadState } from '../../../types'
import { Key, KeyValue, KeyValueContainer, Value } from './KeyValue'
import { prettyNumber } from '../../../utils'

type TruckInfoProps = {
  serviceKey: string
  truck: TruckWithActiveRound
  routes: Map<string, Route>
  carts: Cart[]
  loads: Load[]
  redo: () => void
  onClickRoute: (route: Route) => void
  onClickRegion: (routeName: string) => void
}

export const TruckInfo: React.FC<TruckInfoProps> = ({
  serviceKey,
  truck,
  routes,
  carts,
  loads,
  redo,
  onClickRoute,
  onClickRegion,
}) => {
  const route = !truck.activeRound ? null : routes.get(truck.activeRound!.routeId)
  return (
    <>
      <InfoItem redo={redo} icon="truck" title={`트럭 (${truck.carNumber})`}>
        <KeyValueContainer>
          <KeyValue>
            <Key>고유 ID</Key>
            <Value>{truck.id}</Value>
          </KeyValue>
          <KeyValue>
            <Key>차번호</Key>
            <Value>{truck.carNumber}</Value>
          </KeyValue>
          <KeyValue>
            <Key>카트 수</Key>
            <Value>{prettyNumber(carts.length, '개')}</Value>
          </KeyValue>
          <KeyValue>
            <Key>전체 화물 수</Key>
            <Value>{prettyNumber(loads.length, '개')}</Value>
          </KeyValue>
          <KeyValue>
            <Key>운행 노선</Key>
            <Value>
              {!route ? (
                '-'
              ) : (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    if (route.level === Level.LastMile) {
                      onClickRegion(route.name)
                    } else {
                      onClickRoute(route)
                    }
                  }}
                >
                  {route.name}
                  <FaExternalLinkAlt className="ms-2" />
                </span>
              )}
            </Value>
          </KeyValue>
        </KeyValueContainer>
        <hr />
        <StatContainer>
          <StatItem title={prettyNumber(loads.filter((l) => l.state === LoadState.PendingCollect).length, '개')}>
            인수대기
          </StatItem>
          <StatItem title={prettyNumber(loads.filter((l) => l.state === LoadState.InDelivery).length, '개')}>
            배송중
          </StatItem>
          <StatItem title={prettyNumber(loads.filter((l) => l.state === LoadState.Delivered).length, '개')}>
            배송완료
          </StatItem>
        </StatContainer>
        {(truck.activeRound || truck.recentRound) && (
          <>
            <hr />
            <StatContainer>
              <StatItem title="">
                <Link to={`/simulation/${serviceKey}/rounds/${truck.activeRound?.id || truck.recentRound!.id}`}>
                  <Button variant="outline-primary">운행 상세 정보 보기</Button>
                </Link>
              </StatItem>
            </StatContainer>
          </>
        )}
      </InfoItem>
    </>
  )
}

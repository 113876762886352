import React from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { PageDataProvider } from '../_metronic/layout/core'
import { ActivityDrawer, DrawerMessenger, InviteUsers, Main, UpgradePlan } from '../_metronic/partials'
import { useKeycloakManager, useUserInfo } from '../utils/keycloak'
import Unauthenticated from '../pages/Unauthenticated'
import { useScreen } from '../utils/screen'

const Layout: React.FC = ({ children }) => {
  const { stopRefresh } = useKeycloakManager()
  const { isAuthenticated } = useUserInfo()
  const { needFullScreen } = useScreen()

  // 미로그인 시 경고 페이지 렌더링
  if (!isAuthenticated) {
    stopRefresh()
    return <Unauthenticated />
  }

  const Children = () => {
    return needFullScreen ? (
      <>{children}</>
    ) : (
      <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <Content>{children}</Content>
        </div>
      </div>
    )
  }

  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <HeaderWrapper />
          <Children />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export { Layout }

import React, { FC, useContext, useState } from 'react'

const ScreenContext = React.createContext({
  needFullScreen: false,
  setNeedFullScreen: (needFullScreen: boolean) => {
    // This is intentional
  },
})

/**
 * 풀스크린 사용 여부에 따라 Children 리턴하는 프로바이더
 * @param needFullScreen 풀스크린 사용 여부
 * @param children
 * @constructor
 */
const ScreenProvider: FC = ({ children }) => {
  const [needFullScreen, setNeedFullScreen] = useState(false)

  return <ScreenContext.Provider value={{ needFullScreen, setNeedFullScreen }}>{children}</ScreenContext.Provider>
}

export { ScreenProvider, ScreenContext }

export const useScreen = () => useContext(ScreenContext)

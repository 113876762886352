import React, { useMemo } from 'react'
import { Pagination as BasePagination } from 'react-bootstrap'

type Props = {
  page: number
  pageSize: number
  totalCount: number
  onClickPage: (page: number) => void
}

const Pagination: React.FC<Props> = ({ page, pageSize, totalCount, onClickPage }) => {
  const hasPrevPage = useMemo(() => page > 0, [page])
  const lastPage = useMemo(() => Math.max(0, Math.floor((totalCount - 1) / pageSize)), [pageSize, totalCount])
  const hasNextPage = useMemo(() => page < lastPage, [page, lastPage])
  const siblingPages = useMemo(() => getSiblingPageNumbers(page, lastPage), [page, lastPage])
  return (
    <BasePagination className="col-sm-12 p-5">
      <BasePagination.Item className="previous" disabled={!hasPrevPage} onClick={() => onClickPage(page - 1)}>
        <i className="previous" />
      </BasePagination.Item>
      {siblingPages.map((n) => (
        <BasePagination.Item key={n} active={page === n} onClick={() => onClickPage(n)}>
          {n + 1}
        </BasePagination.Item>
      ))}
      <BasePagination.Item className="next" disabled={!hasNextPage} onClick={() => onClickPage(page + 1)}>
        <i className="next" />
      </BasePagination.Item>
    </BasePagination>
  )
}

export { Pagination }

function getSiblingPageNumbers(page: number, lastPage: number) {
  const prevLen = Array.from(Array(3).keys())
    .map((i) => page - i - 1)
    .filter((n) => n >= 0).length
  const next = Array.from(Array(6 - prevLen).keys())
    .map((i) => page + i + 1)
    .filter((n) => n <= lastPage)
  const prev = Array.from(Array(6 - next.length).keys())
    .map((i) => page - i - 1)
    .filter((n) => n >= 0)
    .reverse()
  return [...prev, page, ...next]
}

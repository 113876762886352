import React from 'react'
import styled from 'styled-components'
import { Card, ListGroup } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa'

type InfoItemProps = {
  icon?: string
  title: string
  subTitle?: string
  list?: boolean
  redo?: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .card {
    border: 1px solid #bbbbbb;
  }

  .card:not(:last-of-type) {
    margin-right: 10px;
  }
  .card.back-button {
    cursor: pointer;
  }
`

const Title = styled.h3`
  font-weight: 900;
  margin-bottom: 20px;

  .fas {
    color: ${(p) => p.theme.primary};
    margin-right: 8px;
  }
`

const BackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Card className="back-button" onClick={onClick}>
      <Card.Body className="p-3">
        <FaArrowLeft />
      </Card.Body>
    </Card>
  )
}

export const InfoItem: React.FC<InfoItemProps> = ({ icon, title, subTitle, list, redo, children }) => {
  return (
    <>
      <Container className="mb-3">
        {redo && <BackButton onClick={redo} />}
        <Card>
          <Card.Body className="p-5">
            <Title className={list ? 'm-0' : ''}>
              {icon && <i className={`fas fa-1x fa-${icon}`} />}
              {icon && ' '}
              {title}
              {subTitle && <span className="text-muted fs-7 ms-3">({subTitle})</span>}
            </Title>
            {!list && <div>{children}</div>}
          </Card.Body>
          {list && <ListGroup variant="flush">{children}</ListGroup>}
        </Card>
      </Container>
    </>
  )
}

export const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`
type StatItemProps = {
  title: string
}

const StatItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin-left: 10px;
  margin-right: 10px;

  & + .stat-item-container {
    margin-left: 30px;
  }

  text-align: center;

  p {
    margin: 4px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const StatItem: React.FC<StatItemProps> = ({ title, children }) => {
  return (
    <StatItemContainer className="stat-item-container">
      <h3>{title}</h3>
      {children}
    </StatItemContainer>
  )
}

import React from 'react'
import { InfoItem, StatContainer, StatItem } from './InfoItem'
import { Load, LoadState, Region, TruckWithActiveRound } from '../../../types'
import { Key, KeyValue, KeyValueContainer, Value } from './KeyValue'
import { TruckItem } from './TruckItem'
import { prettyNumber } from '../../../utils'

type RegionInfoProps = {
  region: Region
  trucks: TruckWithActiveRound[]
  loads: Load[]
  redo: () => void
  onClickTruck: (truck: TruckWithActiveRound) => void
}

export const RegionInfo: React.FC<RegionInfoProps> = ({ region, trucks, loads, redo, onClickTruck }) => {
  return (
    <>
      <InfoItem redo={redo} icon="globe" title={`${region.routeName}`}>
        <KeyValueContainer>
          <KeyValue>
            <Key>서비스 구역명</Key>
            <Value>{region.routeName}</Value>
          </KeyValue>
        </KeyValueContainer>
        <hr />
        <StatContainer>
          <StatItem title={prettyNumber(loads.filter((l) => l.state === LoadState.PendingCollect).length, '개')}>
            <p>인수대기</p>
          </StatItem>
          <StatItem title={prettyNumber(loads.filter((l) => l.state === LoadState.InDelivery).length, '개')}>
            <p>배송중</p>
          </StatItem>
        </StatContainer>
      </InfoItem>
      <InfoItem title="운행 중 트럭" list>
        {!trucks.length ? (
          <StatContainer>
            <StatItem title="">
              <p className="pb-4">없음</p>
            </StatItem>
          </StatContainer>
        ) : (
          Object.values(trucks).map((t) => (
            <TruckItem key={t.id} truck={t} onClick={() => onClickTruck(t)}>
              {t.id}
            </TruckItem>
          ))
        )}
      </InfoItem>
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { StatContainer } from './InfoItem'

type PageBoxProp = {
  title: string
  span?: number
}

export const PageBox: React.FC<PageBoxProp> = ({ title, span, children }) => {
  return (
    <Col sm={span ?? 12}>
      <Card>
        <Card.Body>
          <h3 className="mb-8">{title}</h3>
          {children}
        </Card.Body>
      </Card>
    </Col>
  )
}

export const PageContainer = styled(Container)`
  padding-top: 25px;
  padding-bottom: 70px;
`

export const PageRow = styled(Row)`
  margin-top: 20px;
`

export const PageStatContainer = styled(StatContainer)`
  justify-content: space-between;
  &.single {
    justify-content: center;
  }

  .stat-item-container h3 {
    font-size: calc(2.5rem + 0.22vw);
  }
`

import { FC, Reducer, useState, useReducer, useRef, useEffect } from 'react'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Vega, VisualizationSpec } from 'react-vega'
import { View, DataType, read } from 'vega'
import axios from 'axios'
import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../_metronic/helpers'

const LoadingIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 300px;
  background-color: ${(p) => p.theme.gray200};
  padding: 80px;
`

const LoadingIconWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const LoadingIcon = styled(KTSVG)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  svg {
    width: 100%;
    height: 100%;
  }
`

const Loading: FC = () => {
  return (
    <LoadingIconContainer className="border">
      <LoadingIconWrapper>
        <LoadingIcon path={toAbsoluteUrl('/media/icons/duotune/graphs/gra001.svg')} svgClassName="" />
      </LoadingIconWrapper>
    </LoadingIconContainer>
  )
}

interface Props {
  /**
   * Vega 스펙에 설정된 폭을 그대로 유지할지 여부.
   *
   * 설정되지 않거나 false인 경우 컨테이너의 폭 100%로 설정된다.
   */
  inheritWidth?: boolean

  /**
   * 그래프 높이.
   *
   * 설정되지 않거나 0인 경우 스펙에 설정된 높이를 사용한다.
   * 스펙에 높이가 설정되어있지 않은 경우 기본값 300px을 사용한다.
   */
  height?: number

  /**
   * 스펙 파일 URL.
   */
  specURL: string

  /**
   * 데이터 파일 URL.
   *
   * TSV 형식이어야 한다.
   */
  dataURL: string

  /**
   * 데이터 컬럼별 파싱 옵션을 설정할 수 있다.
   * 설정하지 않은 컬럼은 Vega 엔진이 자동으로 그 타입을 해석한다.
   *
   * boolean / number / date / string 타입 중 하나를 설정할 수 있다.
   */
  parse?: { [field: string]: DataType }
}

const VegaGraph: FC<Props> = function ({ inheritWidth, height, specURL, dataURL, parse }) {
  const ref = useRef<HTMLDivElement>(null)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [view, setView] = useState<View | null>(null)
  const [data, setData] = useState<any>(null)
  const [spec, updateSpec] = useReducer<Reducer<object, object | null>>((prev, spec) => {
    return spec ? { ...prev, ...spec } : {}
  }, {})

  useDeepCompareEffect(() => {
    setLoading(true)
    if (specURL && dataURL) {
      ;(async () => {
        const ax = axios.create({ baseURL: '/' })
        const [spec, data] = await Promise.all([
          (async () => (await ax.get(specURL)).data)(),
          (async () => {
            const { data } = await ax.get(dataURL)
            return read(data, { type: 'tsv', parse: parse || 'auto' })
          })(),
        ])
        updateSpec({
          ...spec,
          width: inheritWidth ? spec.width || 0 : 0,
          height: height || spec.height || 300,
        })
        setData(data)
      })()
    }
    return () => {
      updateSpec(null)
      setData(null)
    }
  }, [inheritWidth, height, specURL, dataURL, parse])

  useEffect(() => {
    if (view && ref.current) {
      view.runAfter((view) => {
        setLoading(false)
      })
      if (!inheritWidth) {
        updateSpec({ width: ref.current.offsetWidth - 10 })
      }
    }
  }, [view, inheritWidth])

  return (
    <div
      className={clsx(
        {
          'w-100': !inheritWidth,
        },
        'position-relative',
      )}
    >
      {isLoading ? <Loading /> : null}
      <div
        ref={ref}
        className={clsx({
          'w-100': !inheritWidth,
          invisible: isLoading,
        })}
      >
        <Vega
          spec={spec as VisualizationSpec}
          data={{ origin: data }}
          renderer="svg"
          actions={false}
          onNewView={(view) => {
            if (data && spec) {
              setView(view)
            }
          }}
        />
      </div>
    </div>
  )
}

export { VegaGraph }

import React from 'react'
import { InfoItem, StatContainer, StatItem } from './InfoItem'
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa'
import { Level, Route, Statistics, TruckWithActiveRound } from '../../../types'
import { prettyNumber, verboseTime } from '../../../utils'

type Prop = {
  stats: Statistics
  trucks: Map<string, TruckWithActiveRound>
  routes: Map<string, Route>
}

export const MainInfo: React.FC<Prop> = ({ stats, trucks, routes }) => {
  const truckStat = Array.from(trucks.values()).reduce(
    (acc, truck) => {
      if (!truck.activeRound) return acc
      const route = routes.get(truck.activeRound.routeId)
      if (!route) return acc
      return !route
        ? acc
        : route.level === Level.LastMile
        ? { ...acc, lastMile: acc.lastMile + 1 }
        : route.level === Level.Local
        ? { ...acc, local: acc.local + 1 }
        : route.level === Level.Wide
        ? { ...acc, wide: acc.wide + 1 }
        : route.level === Level.LMX
        ? { ...acc, pickUp: acc.pickUp + 1 }
        : acc
    },
    {
      lastMile: 0,
      local: 0,
      wide: 0,
      pickUp: 0,
    },
  )
  return (
    <>
      <InfoItem title="현재 배송 현황">
        <StatContainer>
          <StatItem title={prettyNumber(stats.inDeliveryLoadCount, '개')}>
            <p>현재 배송 중</p>
            <p>
              <span className="text-success">
                <FaArrowCircleDown /> -17개
              </span>{' '}
              <span className="text-muted">vs 24시간 전</span>
            </p>
          </StatItem>
          <StatItem title={prettyNumber(stats.deliveredLoadSum, '개')}>
            <p>오늘 배송 완료</p>
            <p>
              <span className="text-success">
                <FaArrowCircleDown /> -43개
              </span>{' '}
              <span className="text-muted">vs 24시간 전</span>
            </p>
          </StatItem>
        </StatContainer>
      </InfoItem>
      <InfoItem title="평균 배송 시간">
        <StatContainer>
          <StatItem
            title={stats.deliveryDuration.localAverage ? verboseTime(stats.deliveryDuration.localAverage) : '-'}
          >
            <p>동네배송</p>
            <p>
              <span className="text-danger">
                <FaArrowCircleUp /> +12m
              </span>
            </p>
          </StatItem>
          <StatItem
            title={stats.deliveryDuration.eveningAverage ? verboseTime(stats.deliveryDuration.eveningAverage) : '-'}
          >
            <p>저녁배송</p>
            <p>
              <span className="text-danger">
                <FaArrowCircleUp /> +34m
              </span>
            </p>
          </StatItem>
          <StatItem
            title={stats.deliveryDuration.overnightAverage ? verboseTime(stats.deliveryDuration.overnightAverage) : '-'}
          >
            <p>새벽배송</p>
            <p>
              <span className="text-success">
                <FaArrowCircleDown /> -9.5m
              </span>
            </p>
          </StatItem>
        </StatContainer>
      </InfoItem>
      <InfoItem title="운행 중 트럭">
        <StatContainer>
          <StatItem title={prettyNumber(truckStat.wide, '대')}>
            <p>광역노선</p>
          </StatItem>
          <StatItem title={prettyNumber(truckStat.local, '대')}>
            <p>지역노선</p>
          </StatItem>
          <StatItem title={prettyNumber(truckStat.lastMile, '대')}>
            <p>라스트마일</p>
          </StatItem>
          <StatItem title={prettyNumber(truckStat.pickUp, '대')}>
            <p>픽업트럭</p>
          </StatItem>
        </StatContainer>
      </InfoItem>
    </>
  )
}

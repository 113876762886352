export interface Region {
  id: string
  routeName: string
  polygon: number[][][]
}

export enum Level {
  Wide = 'WD',
  Local = 'LC',
  LastMile = 'LM',
  LMX = 'LMX',
  Fulfillment = 'FULFILLMENT',
  PickUp = 'PU',
}

export interface Location {
  latitude: number
  longitude: number
}

export interface Manager {
  id: string
  fullName: string
  phone: string
  startWorkTime: string
  endWorkTime: string
}

export enum StationType {
  WideInDoor = 'WIDE_IN_DOOR',
  WideOnTruck = 'WIDE_ON_TRUCK',
  Local = 'LOCAL',
  RELAY = 'RELAY',
  PickUp = 'PICK_UP',
}

export interface Station {
  id: string
  name: string
  type: StationType
  level: Level
  address: string
  location: Location
  managers: Array<Manager>
}

export enum RouteType {
  Wide = 'WD_GENERAL',
  Local = 'LC_GENERAL',
  LastMile = 'LM_GENERAL',
  PickUp = 'LM_PICK_UP',
}

export interface Route {
  id: string
  name: string
  level: Level
  type: RouteType
  stations: Station[]
  regionSetName?: string
}

export interface Driver {
  id: string
  fullName: string
  assignedRouteId?: string
  recentRound?: Round
}

export enum RoundState {
  InShipping = 'IN_SHIPPING',
  AtStation = 'AT_STATION',
  OnWayBack = 'ON_WAY_BACK',
  Finished = 'FINISHED',
}

export interface Round {
  id: string
  state: RoundState
  truckId: string
  driverId: string
  routeId: string
  startTime: string
  endTime?: string
  location: Location
}

export type RoundWithRecentLocations = Round & { recentLocations: Location[] }

export interface Truck {
  id: string
  carNumber: string
  cartCapacity: number
  stationId?: string
  sourceStationId?: string
  targetStationId?: string
  location: Location
  recentRound?: Round
  // generated
  level: Level
  type: RouteType
}

export type TruckWithActiveRound = Truck & {
  activeRound?: RoundWithRecentLocations
}

export interface TraceEvent {
  id: string
  eventTime: string
  location: Location
}

export interface StatusEvent {
  eventTime: string
  eventType: StatusEventType
  loadState: LoadState
  roundId: string
  sourceStationName: string
  targetStationName: string
  sourceStationId: string
  targetStationId: string
}

export enum StatusEventType {
  PickingUpLoad = 'PICKING_UP_LOAD',
  ArrivalAtStation = 'ARRIVAL_AT_STATION',
  DepartureFromStation = 'DEPARTURE_FROM_STATION',
  ReturningToStation = 'RETURNING_TO_STATION',
  DeliveringLoad = 'DELIVERING_LOAD',
}

export enum CartState {
  InShipping = 'IN_SHIPPING',
  BeforeUnloading = 'BEFORE_UNLOADING',
  Unloading = 'UNLOADING',
  Empty = 'EMPTY',
  Loading = 'LOADING',
  StandBy = 'STAND_BY',
}

export enum CartType {
  Idle = 'IDLE',
  Shipping = 'SHIPPING',
  LMReturn = 'LM_RETURN',
  LocalDeliver = 'LOCAL_DELIVER',
  LocalCollect = 'LOCAL_COLLECT',
}

export interface Cart {
  id: string
  state: CartState
  type: CartType
  truckId?: string
  positionInTruck?: string
  plannedTruckId?: string
  stationId?: string
  sourceStationId?: string
  targetStationId?: string
  targetRouteId?: string
  arrivalTime?: string
  standByTime?: string
}

export enum LoadState {
  PendingCollect = 'PENDING_COLLECT',
  HoldingCollect = 'HOLDING_COLLECT',
  InCollect = 'IN_COLLECT',
  InTransport = 'IN_TRANSPORT',
  InDelivery = 'IN_DELIVERY',
  HoldingDelivery = 'HOLDING_DELIVERY',
  AtStation = 'AT_STATION',
  Delivered = 'DELIVERED',
  Quit = 'QUIT',
  // legacy
  OnHold = 'ON_HOLD',
}

export enum DeliveryClass {
  Local = 'LOCAL',
  Evening = 'EVENING',
  Overnight = 'OVERNIGHT',
  Returning = 'RETURNING',
}

export interface StationIDAndSequence {
  id: string
  sequence: number
}

export interface ShippingPathItem {
  routeId: string
  sourceStation?: StationIDAndSequence
  targetStation?: StationIDAndSequence
  finished: boolean
}

export interface AddressInfo {
  postalCode: string
  regionBaseAddress: string
  regionDetailAddress: string
  streetBaseAddress: string
  streetDetailAddress: string
  buildingName: string
  buildingNumber: string
  buildingId: string
}

export interface EndUserInfo {
  name: string
  phone: string
  rawAddress: string
  location: Location
  lastMileInfo: LastMileInfo
}

export interface LastMileInfo {
  regionId: string
  regionSetPolicyType: string
  regionSetName: string
}

export interface Dimension {
  width: number
  depth: number
  height: number
}

export interface PickUpInfo {
  pickUpTime: string
  imageUrl?: string
  handOverMethod: string
  specialNote: string
  extraPayload: any
}

export interface DeliverInfo {
  deliverTime: string
  imageUrl: string
  handOverMethod: string
  specialNote: string
  extraPayload: any
}

export interface Load {
  id: string
  orderId: string
  invoiceNumber: string
  state: LoadState
  deliveryClass: DeliveryClass
  orderTime: string
  cartId?: string
  truckId?: string
  plannedTruckId?: string
  shippingPlaceId?: string
  stationId?: string
  shippingPath: ShippingPathItem[]
  sourceStationId?: string
  targetStationId?: string
  name: string
  dimension: Dimension
  category: string
  fragile: boolean
  weight: number
  sender: EndUserInfo
  receiver: EndUserInfo
  accessMethod: string
  deliveryPreference: string
  pickUpInfo?: PickUpInfo
  deliveryInfo?: DeliverInfo
}

export enum ServiceStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  UNSUPPORTED = 'UNSUPPORTED',
}

export interface ShippingPlace {
  id: string
  clientId: string
  name: string
  serviceStatus: ServiceStatus
  addressInfo: AddressInfo
  location: Location
  acceptReturn: boolean
}

export type LoadWithShippingPlace = Load & {
  shippingPlace: ShippingPlace
}

export interface StatisticsItem {
  name: string
  count: number
}

export interface MergedStatistics {
  deliveredLoadCount: StatisticsItem[]
}

export interface Statistics {
  createdLoadCount: number
  createdLoadSum: number
  inDeliveryLoadCount: number
  deliveredLoadCount: number
  deliveredLoadSum: number
  collectedLoadCount: number
  collectedLoadSum: number
  deliveryDuration: {
    totalAverage: number
    totalAverage4h: number
    localAverage: number
    eveningAverage: number
    overnightAverage: number
  }
}

export enum LoadIDType {
  UUID = 'id',
  InvoiceNumber = 'invoice_number',
}

export enum TruckIDType {
  UUID = 'id',
  CarNumber = 'car_number',
}

export enum OrderState {
  TakingOut = 'TAKING_OUT',
  PendingCollect = 'PENDING_COLLECT',
  InShipping = 'IN_SHIPPING',
  Delivered = 'DELIVERED',
  Quit = 'QUIT',
  Canceled = 'CANCELED',
}

export interface Order {
  orderId: string
  invoiceNumber: string
  state: OrderState
  deliveryClass: DeliveryClass
  orderTime: string
  takeOutTime: string
  resolveTime: string
  brokerClientId: string
  clientId: string
  shippingPlaceId: string
  abnormalResolveReason: string
  sender: EndUserInfo
  receiver: EndUserInfo
  clientOrderId: string
  note: string
  returningInfo?: ReturningInfo
  products: Product[]
}

export interface ReturningInfo {
  invoiceNumber: string
}

export interface Product {
  id: string
  name: string
  category: string
  fragile: boolean
  price: number
  sellerName: string
  clientProductId: string
}

export enum ClientRole {
  Normal = 'NORMAL',
  Broker = 'BROKER',
  SME = 'SME',
}

export interface Client {
  clientId: string
  role: ClientRole
  name: string
  registrationNumber: string
  active: boolean
}

import React from 'react'
import { InfoItem } from './InfoItem'
import { Route, Station } from '../../../types'
import { toVerbose } from '../../../utils/models'
import { KeyValueContainer, KeyValue, Key, Value } from './KeyValue'
import { StationItem } from './StationItem'
import { prettyNumber } from '../../../utils'

type RouteInfoProps = {
  route: Route
  redo: () => void
  onClickStation: (station: Station) => void
}

export const RouteInfo: React.FC<RouteInfoProps> = ({ route, redo, onClickStation }) => {
  return (
    <>
      <InfoItem redo={redo} icon="route" title={`${route.name.split(':').reverse()[0]} 노선`}>
        <KeyValueContainer>
          <KeyValue>
            <Key>노선 레벨</Key>
            <Value>{toVerbose(route.level)} 노선</Value>
          </KeyValue>
          <KeyValue>
            <Key>소속 정류소 개수</Key>
            <Value>{prettyNumber(route.stations.length, '개')}</Value>
          </KeyValue>
        </KeyValueContainer>
      </InfoItem>
      <InfoItem title="소속 정류소" list>
        {route.stations.map((station, i) => (
          <StationItem station={station} key={`${i}-${station.name}`} onClick={() => onClickStation(station)} />
        ))}
      </InfoItem>
    </>
  )
}

import React, { FC, useEffect } from 'react'
import type { RouteProps } from 'react-router-dom'
import { Route as BaseRoute } from 'react-router-dom'

import PermissionDenied from '../pages/PermissionDenied'
import { andRoles, Role, usePermission } from '../utils/keycloak'
import { useScreen } from '../utils/screen'

const noRolesRequired = andRoles()

// 기존 <Route>에 requiredRole을 추가적으로 입력할 수 있게 하여
// PermissionProvider의 기능을 활용해 권한이 없는 경우 Unauthorized 페이지를 렌더링한다.
export const Route: FC<RouteProps & { requiredRole?: Role; needFullScreen?: boolean }> = (props) => {
  const { hasPermission } = usePermission(props.requiredRole || noRolesRequired)
  const { setNeedFullScreen } = useScreen()
  useEffect(() => {
    setNeedFullScreen(props.needFullScreen ?? false)
  }, [setNeedFullScreen, props.needFullScreen])
  const routeProps = props as RouteProps
  return hasPermission ? <BaseRoute {...routeProps}>{props.children}</BaseRoute> : <PermissionDenied />
}

export const ChildRoute = BaseRoute

import React from 'react'
import { InfoItem, StatContainer, StatItem } from './InfoItem'
import { Station, TruckWithActiveRound, Cart, CartState, Level } from '../../../types'
import { Key, KeyValue, KeyValueContainer, Value } from './KeyValue'
import { TruckItem } from './TruckItem'
import { prettyNumber } from '../../../utils'
import { useTime } from './TimeController'

type StationInfoProps = {
  station: Station
  trucks: TruckWithActiveRound[]
  carts: Cart[]
  redo: () => void
  onClickTruck: (truck: TruckWithActiveRound) => void
}

export const StationInfo: React.FC<StationInfoProps> = ({ station, trucks, carts, onClickTruck, redo }) => {
  const { time: now } = useTime()
  const manager =
    station.managers.find((m) => m.startWorkTime <= now && now <= m.endWorkTime) ||
    (station.managers.length ? station.managers[0] : null)
  return (
    <>
      <InfoItem
        redo={redo}
        icon="map-marked-alt"
        title={
          station.level === Level.Fulfillment
            ? station.name.split(':').reverse()[0]
            : `${station.name.split(':').reverse()[0]} 정류소`
        }
      >
        {station.level !== Level.Fulfillment ? (
          <>
            <KeyValueContainer>
              <KeyValue>
                <Key>관리자</Key>
                <Value>{manager ? `${manager.fullName} (~ ${manager.endWorkTime})` : '-'}</Value>
              </KeyValue>
              <KeyValue>
                <Key>연락처</Key>
                <Value>{manager?.phone || '-'}</Value>
              </KeyValue>
              <KeyValue>
                <Key>근로자</Key>
                <Value>{station.managers.length}명</Value>
              </KeyValue>
            </KeyValueContainer>
            <hr />
            <StatContainer
              style={{
                minWidth: 420,
              }}
            >
              <StatItem title={prettyNumber(carts.filter((c) => c.state === CartState.BeforeUnloading).length, '개')}>
                <p>분류대기 카트</p>
              </StatItem>
              <StatItem
                title={prettyNumber(
                  carts.filter((c) => c.state === CartState.Loading || c.state === CartState.Unloading).length,
                  '개',
                )}
              >
                <p>분류중 카트</p>
              </StatItem>
              <StatItem title={prettyNumber(carts.filter((c) => c.state === CartState.StandBy).length, '개')}>
                <p>트럭대기 카트</p>
              </StatItem>
              <StatItem title={prettyNumber(carts.filter((c) => c.state === CartState.Empty).length, '개')}>
                <p>빈 카트</p>
              </StatItem>
            </StatContainer>
          </>
        ) : (
          <KeyValueContainer>
            <KeyValue>
              <Key>타입</Key>
              <Value>풀필먼트 센터</Value>
            </KeyValue>
          </KeyValueContainer>
        )}
      </InfoItem>
      {trucks.length && (
        <InfoItem title="정류소 내 트럭" list>
          {trucks.map((t) => (
            <TruckItem key={t.id} truck={t} onClick={() => onClickTruck(t)}>
              {t.carNumber}
            </TruckItem>
          ))}
        </InfoItem>
      )}
    </>
  )
}

import { FC, createContext, useContext, useState, Dispatch, SetStateAction } from 'react'
import moment, { Moment } from 'moment'

const DateContext = createContext<[Moment, Dispatch<SetStateAction<Moment>>]>([moment(), () => {}])

export const DateProvider: FC = ({ children }) => {
  const [date, setDate] = useState<Moment>(moment())
  return <DateContext.Provider value={[date, setDate]}>{children}</DateContext.Provider>
}

export function useDate() {
  return useContext(DateContext)
}

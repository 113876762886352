import React, { useState, useCallback } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import { Card, Dropdown, Form } from 'react-bootstrap'

const Toggle = React.forwardRef<
  HTMLDivElement,
  {
    onClick: React.MouseEventHandler
  }
>(({ children, onClick }, ref) => (
  <div
    ref={ref}
    className="fs-7 fw-normal cursor-pointer"
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
    <FaAngleDown />
  </div>
))

export type ResourceTypeSelectorType = {
  wideStation: boolean
  localStation: boolean
  truck: boolean
  load: boolean
  region: boolean
  autoPan: boolean
}

type Props = {
  onChange: (value: ResourceTypeSelectorType) => void
}

export const ResourceTypeSelector: React.FC<Props> = ({ onChange }) => {
  const [state, setState] = useState<ResourceTypeSelectorType>({
    wideStation: true,
    localStation: true,
    truck: true,
    load: true,
    region: true,
    autoPan: true,
  })
  const update = useCallback(
    (key: keyof ResourceTypeSelectorType, e: React.ChangeEvent<HTMLInputElement>) => {
      const newState = { ...state, [key]: e.target.checked }
      if (key === 'localStation' && newState[key]) {
        newState.wideStation = true
      }
      if (key === 'wideStation' && !newState[key]) {
        newState.localStation = false
      }
      setState(newState)
      onChange(newState)
    },
    [onChange, state],
  )
  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle as={Toggle} variant="light" id="dropdown-basic">
        디스플레이
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Card.Body>
          <Form.Check
            id="resource-type-selector-wide-station"
            type="checkbox"
            label="광역 정류소"
            checked={state.wideStation}
            onChange={(e) => update('wideStation', e)}
            className="mb-4"
          />
          <Form.Check
            id="resource-type-selector-local-station"
            type="checkbox"
            label="지역 정류소"
            checked={state.localStation}
            onChange={(e) => update('localStation', e)}
            className="mb-4"
          />
          <Form.Check
            id="resource-type-selector-truck"
            type="checkbox"
            label="트럭"
            checked={state.truck}
            onChange={(e) => update('truck', e)}
            className="mb-4"
          />
          <Form.Check
            id="resource-type-selector-load"
            type="checkbox"
            label="화물"
            checked={state.load}
            onChange={(e) => update('load', e)}
            className="mb-4"
          />
          <Form.Check
            id="resource-type-selector-region"
            type="checkbox"
            label="서비스 영역"
            checked={state.region}
            onChange={(e) => update('region', e)}
            className="mb-4"
          />
          <Form.Check
            id="resource-type-selector-auto-pan"
            type="checkbox"
            label="지도 자동 이동"
            checked={state.autoPan}
            onChange={(e) => update('autoPan', e)}
          />
        </Card.Body>
      </Dropdown.Menu>
    </Dropdown>
  )
}

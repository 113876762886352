export function FallbackView() {
  return (
    <div className="splash-screen text-center fs-1 mt-5 pt-5">
      <div className="mb-2">
        <i className="fas text-black fs-1 fa-spinner fa-spin" />
      </div>
      <div>
        <span>Loading ...</span>
      </div>
    </div>
  )
}

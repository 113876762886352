import { FC, createContext, useState, useEffect, useContext } from 'react'
import { UserInfo, useUserInfo } from './AuthenticationProvider'

/**
 * 역할 조건.
 *
 * {@link roles()}, {@link realmRoles()}, {@link andRoles()}, {@link orRoles()}를 조합하여 생성할 수 있다.
 */
export interface Role {
  /**
   * 주어진 유저가 본 역할 조건을 만족시키는 적절한 역할을 가지고 있는지 여부를 반환한다.
   * @param userInfo 주어진 유저 정보
   */
  hasRole(userInfo: UserInfo): boolean
}

class SingleRole implements Role {
  role: string
  isRealmRole: boolean

  constructor(role: string, isRealmRole: boolean = false) {
    this.role = role
    this.isRealmRole = isRealmRole
  }

  hasRole(userInfo: UserInfo): boolean {
    const roles = this.isRealmRole ? userInfo.realmRoles : userInfo.clientRoles
    return roles.some((r) => this.role === r || this.role.startsWith(`${r}:`))
  }
}

class OrRoles implements Role {
  roles: Array<Role>

  constructor(...roles: Array<Role>) {
    this.roles = roles
  }

  hasRole(userInfo: UserInfo): boolean {
    return this.roles.some((r) => r.hasRole(userInfo))
  }
}

class AndRoles implements Role {
  roles: Array<Role>

  constructor(...roles: Array<Role>) {
    this.roles = roles
  }

  hasRole(userInfo: UserInfo): boolean {
    return this.roles.every((r) => r.hasRole(userInfo))
  }
}

/**
 * 인자로 전달된 Realm 역할 중 하나라도 존재해야 접근 권한이 생긴다.
 *
 * @param roles Realm 역할들
 */
export function realmRoles(...roles: Array<string>): Role {
  return new OrRoles(...roles.map((r) => new SingleRole(r, true)))
}

/**
 * 인자로 전달된 클라이언트 역할 중 하나라도 존재해야 접근 권한이 생긴다.
 *
 * @param roles
 */
export function roles(...roles: Array<string>): Role {
  return new OrRoles(...roles.map((r) => new SingleRole(r)))
}

/**
 * 여러 역할 조건들 모두를 만족하는 역할 조건을 생성한다.
 *
 * {@link roles()}와 {@link realmRoles()}로 만든 기본 역할 조건들을 체이닝하여 복잡한 역할 조건을 만들 수 있다.
 *
 * @param roles 역할 조건들
 */
export function andRoles(...roles: Array<Role>): Role {
  return new AndRoles(...roles)
}

/**
 * 여러 역할 조건들 중 하나 이상을 만족하는 역할 조건을 생성한다.
 *
 * {@link roles()}와 {@link realmRoles()}로 만든 기본 역할 조건들을 체이닝하여 복잡한 역할 조건을 만들 수 있다.
 *
 * @param roles 역할 조건들
 */
export function orRoles(...roles: Array<Role>): Role {
  return new OrRoles(...roles)
}

const PermissionSettingContext = createContext<(role: Role) => void>(() => {})

/**
 * 현재 접근 권한 상태 정보.
 */
export interface PermissionStatus {
  /**
   * 현재 페이지에 대한 접근 권한이 있는지 여부.
   */
  hasPermission: boolean
}

const PermissionContext = createContext<PermissionStatus>({ hasPermission: true })

/**
 * 로그인 된 유저의 권한 처리 정보를 제공하는 프로바이더.
 *
 * @requires AuthenticationProvider
 * @param children
 * @constructor
 */
export const PermissionProvider: FC = ({ children }) => {
  const userInfo = useUserInfo()
  const [role, setRole] = useState<Role>(andRoles)
  const hasPermission = role.hasRole(userInfo)
  return (
    <PermissionSettingContext.Provider value={setRole}>
      <PermissionContext.Provider value={{ hasPermission }}>{children}</PermissionContext.Provider>
    </PermissionSettingContext.Provider>
  )
}

/**
 * 권한이 존재하는지 여부를 반환하는 hook.
 *
 * @requires PermissionProvider
 */
export function usePermissionStatus() {
  return useContext(PermissionContext)
}

const noRolesRequired = andRoles()

/**
 * 현재 페이지를 접근하기 위해 필요한 역할 조건을 설정한다.
 *
 * @requires PermissionProvider
 * @param role 필요한 역할 조건
 * @returns PermissionStatus 권한 존재 여부
 */
export function usePermission(role: Role) {
  const shouldHaveRoles = useContext(PermissionSettingContext)
  useEffect(() => {
    shouldHaveRoles(role)
    return () => {
      shouldHaveRoles(noRolesRequired)
    }
  }, [role, shouldHaveRoles])
  return usePermissionStatus()
}

import React, { FC, lazy, Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { Route } from './Route'
import { Layout } from '../layouts'
import SimulationRoutes from './SimulationRoutes'
import AnalyticsRoutes from './AnalyticsRoutes'

import NotFound from '../pages/NotFound'
import { FallbackView } from '../_metronic/partials'
import { DateProvider } from '../pages/dashboard/DateProvider'
import { roles } from '../utils/keycloak'

const RootRoutes: FC = () => {
  const Profile = lazy(() => import('../pages/profile/Profile'))
  const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'))
  const Statistics = lazy(() => import('../pages/dashboard/Statistics'))
  const LoadList = lazy(() => import('../pages/dashboard/LoadList'))
  const LoadDetail = lazy(() => import('../pages/dashboard/LoadDetail'))
  const TruckList = lazy(() => import('../pages/dashboard/TruckList'))
  const TruckDetail = lazy(() => import('../pages/dashboard/TruckDetail'))
  const APIMain = lazy(() => import('../pages/api/APIMain'))
  const APIAuth = lazy(() => import('../pages/api/APIAuth'))
  const APIErrors = lazy(() => import('../pages/api/APIErrors'))
  const APIReleases = lazy(() => import('../pages/api/APIReleases'))
  const SwaggerDocs = lazy(() => import('../pages/api/SwaggerDocs'))
  const ClientList = lazy(() => import('../pages/client/ClientList'))
  const ClientDetail = lazy(() => import('../pages/client/ClientDetail'))
  const ClientRegister = lazy(() => import('../pages/client/ClientRegister'))

  return (
    <DateProvider>
      <Switch>
        <Layout>
          <Suspense fallback={<FallbackView />}>
            <Switch>
              <Redirect path="/" exact to="/dashboard" />
              <Route path="/simulation" component={SimulationRoutes} needFullScreen requiredRole={roles('admin')} />
              <Route path="/profile" exact component={Profile} />
              <Route path="/dashboard" exact component={Dashboard} needFullScreen />
              <Route path="/dashboard/stats" exact component={Statistics} needFullScreen />
              <Route
                path="/dashboard/analytics"
                component={AnalyticsRoutes}
                needFullScreen
                requiredRole={roles('admin')}
              />
              <Route path="/dashboard/loads" exact component={LoadList} />
              <Route path="/dashboard/loads/:id" exact component={LoadDetail} />
              <Route path="/dashboard/trucks" exact component={TruckList} />
              <Route path="/dashboard/trucks/:id" exact component={TruckDetail} />
              <Route
                path="/clients"
                exact
                component={ClientList}
                requiredRole={roles('clients:viewer')}
                needFullScreen
              />
              <Route
                path="/clients/register"
                exact
                component={ClientRegister}
                requiredRole={roles('clients:manager')}
                needFullScreen
              />
              <Route
                path="/clients/:clientId"
                exact
                component={ClientDetail}
                requiredRole={roles('clients:viewer')}
                needFullScreen
              />
              <Redirect path="/api" exact to="/api/docs" />
              <Route path="/api/docs" exact component={APIMain} requiredRole={roles('admin')} />
              <Route path="/api/docs/auth" exact component={APIAuth} requiredRole={roles('admin')} />
              <Route path="/api/docs/errors" exact component={APIErrors} requiredRole={roles('admin')} />
              <Route path="/api/docs/releases" exact component={APIReleases} requiredRole={roles('admin')} />
              <Redirect path="/api/refs" exact to="/api/docs" />
              <Route path="/api/refs/:id" exact component={SwaggerDocs} requiredRole={roles('admin')} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Layout>
      </Switch>
    </DateProvider>
  )
}

export default RootRoutes

import React from 'react'
import { ListGroupItem, Row, Badge } from 'react-bootstrap'
import { RoundState, TruckWithActiveRound } from '../../../types'
import styled from 'styled-components'

const Col = styled.div`
  flex: 0 0 content;
`

type Props = {
  truck: TruckWithActiveRound
  key: string
  onClick: () => void
}

export const TruckItem: React.FC<Props> = ({ truck, onClick }) => {
  const roundState = truck.activeRound?.state
  const status = !roundState
    ? '대기중'
    : roundState === RoundState.AtStation
    ? '정차중'
    : roundState === RoundState.OnWayBack
    ? '복귀중'
    : roundState === RoundState.InShipping
    ? '운송중'
    : '-'
  return (
    <ListGroupItem onClick={onClick} className="cursor-pointer">
      <Row>
        <Col>
          <Badge bg={status === '정차중' ? 'primary' : 'light'} text={status === '정차중' ? 'white' : 'dark'}>
            {status}
          </Badge>
        </Col>
        <Col>{truck.carNumber}</Col>
      </Row>
    </ListGroupItem>
  )
}

import Keycloak, { KeycloakInstance } from 'keycloak-js'

/**
 * 현재 Keycloak 세션 정보.
 */
export interface KeycloakContext {
  /**
   * Keycloak 인증 여부.
   *
   * @default false
   */
  isAuthenticated: boolean

  /**
   * Keycloak이 발급한 액세스 토큰.
   *
   * @default ""
   */
  accessToken: string

  /**
   * Keycloak이 발급한 리프레시 토큰.
   *
   * @default ""
   */
  refreshToken: string

  /**
   * Keycloak이 발급한 ID 토큰
   *
   * @default ""
   */
  idToken: string

  /**
   * 유저 신원 정보.
   *
   * @default {}
   */
  identity: any

  /**
   * 유저의 Realm 역할 목록.
   *
   * @default []
   */
  realmRoles: Array<string>

  /**
   * 유저의 현 Keycloak 클라이언트 역할 목록.
   *
   * @default []
   */
  clientRoles: Array<string>
}

const STORAGE_KEY = '_keycloak_context'

/**
 * 현재 {@link KeycloakContext}를 로컬 스토리지로부터 읽어 반환한다.
 */
export function getKeycloakContext(): KeycloakContext {
  const val = localStorage.getItem(STORAGE_KEY)
  return val
    ? (JSON.parse(val) as KeycloakContext)
    : {
        isAuthenticated: false,
        accessToken: '',
        refreshToken: '',
        idToken: '',
        identity: {},
        realmRoles: [],
        clientRoles: [],
      }
}

/**
 * 현재 액세스 토큰을 로컬 스토리지로부터 읽어 반환한다.
 */
export function getAccessToken(): string {
  return getKeycloakContext().accessToken
}

/**
 * {@link KeycloakContext}가 변경되면 발생하는 이벤트 명.
 */
export const KEYCLOAK_CONTEXT_CHANGE_EVENT = 'keycloak-context-change'

const event = new Event(KEYCLOAK_CONTEXT_CHANGE_EVENT)

function setKeycloakContext(context: KeycloakContext) {
  window.dispatchEvent(event)
  localStorage.setItem(STORAGE_KEY, JSON.stringify(context))
}

function clearKeycloakContext() {
  window.dispatchEvent(event)
  localStorage.removeItem(STORAGE_KEY)
}

function convertToKeycloakContext(keycloak: KeycloakInstance, clientId: string): KeycloakContext {
  return {
    isAuthenticated: keycloak.authenticated || false,
    accessToken: keycloak.token || '',
    refreshToken: keycloak.refreshToken || '',
    idToken: keycloak.idToken || '',
    identity: keycloak.idTokenParsed || {},
    realmRoles: keycloak.tokenParsed?.realm_access?.roles || [],
    clientRoles: keycloak.tokenParsed?.resource_access?.[clientId]?.roles || [],
  }
}

/**
 * Keycloak 매니저 생성 옵션.
 */
export interface KeycloakManagerOption {
  /**
   * Keycloak 서버 URL.
   *
   * 보통 뒤에 /auth 패스가 붙는다.
   */
  url: string

  /**
   * Keycloak Realm 명.
   */
  realm: string

  /**
   * Keycloak 클라이언트 ID.
   */
  clientId: string

  /**
   * Keycloak OAuth2 연동 시 사용할 스코프.
   *
   * @default "openid email profile phone"
   */
  scope?: string
}

/**
 * Keycloak 매니저.
 *
 * 매니저는 토큰 라이프사이클을 관리한다.
 * 로컬 스토리지를 통해 인증 정보를 영속화 하고,
 * 액세스 토큰 만료 이전에 리프레시 토큰을 통해 액세스 토큰을 재발급 받는다.
 */
export interface KeycloakManager {
  /**
   * Keycloak 세션에서 로그아웃 한다.
   */
  logout(): Promise<void>

  /**
   * 액세스 토큰 갱신을 중지한다.
   */
  stopRefresh(): void
}

/**
 * 새로운 Keycloak 매니저를 구동한다.
 */
export async function runKeycloakManager({
  url,
  realm,
  clientId,
  scope = 'openid email profile phone',
}: KeycloakManagerOption): Promise<KeycloakManager> {
  const keycloak = Keycloak({
    url: url,
    realm: realm,
    clientId: clientId,
  })

  const login = keycloak.login
  keycloak.login = (options = {}) => {
    options.scope = scope
    return login(options)
  }

  const ok = await keycloak.init({ onLoad: 'login-required' })
  if (!ok) {
    throw new Error('keycloak login failed')
  }

  setKeycloakContext(convertToKeycloakContext(keycloak, clientId))
  const intervalId = setInterval(async () => {
    const refreshed = await keycloak.updateToken(61)
    if (refreshed) {
      setKeycloakContext(convertToKeycloakContext(keycloak, clientId))
    }
  }, 30000)
  return {
    async logout() {
      clearInterval(intervalId)
      clearKeycloakContext()
      await keycloak.logout()
    },
    stopRefresh() {
      clearInterval(intervalId)
    },
  }
}

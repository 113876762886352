import React, { FC, lazy } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { Route } from './Route'

import NotFound from '../pages/NotFound'
import { Recurrence } from '../pages/dashboard/Analytics'

const AnalyticsRoutes: FC = () => {
  const Analytics = lazy(() => import('../pages/dashboard/Analytics'))

  return (
    <>
      <Switch>
        <Redirect path="/dashboard/analytics" exact to="/dashboard/analytics/summary" />
        <Redirect path="/dashboard/analytics/summary" exact to="/dashboard/analytics/summary/daily" />
        <Route
          path="/dashboard/analytics/summary/daily"
          exact
          needFullScreen
          render={(props) => (
            <Analytics {...props} title="데일리 분석 요약" viewId="3a5e0f80-f0c1-11ec-abb4-9f2051701924" />
          )}
        />
        <Route
          path="/dashboard/analytics/summary/weekly"
          exact
          needFullScreen
          render={(props) => (
            <Analytics
              {...props}
              title="위클리 분석 요약"
              viewId="3a5e0f80-f0c1-11ec-abb4-9f2051701924"
              recurrence={Recurrence.WEEKLY}
            />
          )}
        />
        <Route
          path="/dashboard/analytics/summary/monthly"
          exact
          needFullScreen
          render={(props) => (
            <Analytics
              {...props}
              title="먼슬리 분석 요약"
              viewId="3a5e0f80-f0c1-11ec-abb4-9f2051701924"
              recurrence={Recurrence.MONTHLY}
            />
          )}
        />
        <Route
          path="/dashboard/analytics/operation/daily"
          exact
          needFullScreen
          render={(props) => (
            <Analytics {...props} title="데일리 운영 분석" viewId="9be9d4d0-fe7e-11ec-b700-7b6dd918cff5" />
          )}
        />
        <Route
          path="/dashboard/analytics/operation/weekly"
          exact
          needFullScreen
          render={(props) => (
            <Analytics
              {...props}
              title="위클리 운영 분석"
              viewId="9be9d4d0-fe7e-11ec-b700-7b6dd918cff5"
              recurrence={Recurrence.WEEKLY}
            />
          )}
        />
        <Route
          path="/dashboard/analytics/operation/monthly"
          exact
          needFullScreen
          render={(props) => (
            <Analytics
              {...props}
              title="먼슬리 운영 분석"
              viewId="9be9d4d0-fe7e-11ec-b700-7b6dd918cff5"
              recurrence={Recurrence.MONTHLY}
            />
          )}
        />
        <Route
          path="/dashboard/analytics/infra/daily"
          exact
          needFullScreen
          render={(props) => (
            <Analytics {...props} title="데일리 인프라 분석" viewId="2eaa6b60-f03b-11ec-87e7-7f53ef757c80" />
          )}
        />
        <Route
          path="/dashboard/analytics/infra/weekly"
          exact
          needFullScreen
          render={(props) => (
            <Analytics
              {...props}
              title="위클리 인프라 분석"
              viewId="2eaa6b60-f03b-11ec-87e7-7f53ef757c80"
              recurrence={Recurrence.WEEKLY}
            />
          )}
        />
        <Route
          path="/dashboard/analytics/infra/monthly"
          exact
          needFullScreen
          render={(props) => (
            <Analytics
              {...props}
              title="먼슬리 인프라 분석"
              viewId="2eaa6b60-f03b-11ec-87e7-7f53ef757c80"
              recurrence={Recurrence.MONTHLY}
            />
          )}
        />
        <Route
          path="/dashboard/analytics/sales/monthly"
          exact
          needFullScreen
          render={(props) => (
            <Analytics
              {...props}
              title="먼슬리 세일즈 분석"
              viewId="834c6720-f038-11ec-87e7-7f53ef757c80"
              recurrence={Recurrence.MONTHLY}
            />
          )}
        />
        <Route
          path="/dashboard/analytics/fulfilment/monthly"
          exact
          needFullScreen
          render={(props) => (
            <Analytics
              {...props}
              title="먼슬리 풀필먼트별 분석"
              viewId="67c0e1a0-fe77-11ec-a7ad-af70ccf1364d"
              recurrence={Recurrence.MONTHLY}
            />
          )}
        />
        <Route
          path="/dashboard/analytics/finance/annual"
          exact
          needFullScreen
          render={(props) => (
            <Analytics
              {...props}
              title="애뉴얼 파이낸스 분석"
              viewId="5adf09d0-fe09-11ec-a7ad-af70ccf1364d"
              recurrence={Recurrence.ANNUAL}
            />
          )}
        />
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default AnalyticsRoutes
